import { fido2Util, hybridResponseService } from 'core-roblox-utilities';
import { FeatureTarget } from '../../../../../../Roblox.CoreScripts.WebApp/Roblox.CoreScripts.WebApp/js/core/services/hybridResponseService';
import { Result } from '../../result';
import { toResultNonAxios } from '../common';
import * as fido2 from '../types/fido2';

export const getNativeResponse = (
  feature: FeatureTarget,
  parameters: Record<string, unknown>,
  timeoutMilliseconds: number
): Promise<Result<fido2.GetNativeResponseReturnType, fido2.Fido2Error | null>> =>
  toResultNonAxios(
    hybridResponseService.getNativeResponse(feature, parameters, timeoutMilliseconds),
    fido2.Fido2Error,
    (credentialString: string | null) => {
      if (credentialString === null) {
        return null;
      }
      return fido2Util.formatCredentialAuthenticationResponseApp(credentialString);
    }
  );

export const getNavigatorCredentials = (
  options?: CredentialRequestOptions
): Promise<Result<fido2.GetNavigatorCredentialsReturnType, fido2.Fido2Error | null>> =>
  toResultNonAxios(navigator.credentials.get(options), fido2.Fido2Error).then(result =>
    Result.map(result, (credential: Credential | null) => {
      if (credential === null) {
        return null;
      }
      return fido2Util.formatCredentialAuthenticationResponseWeb(credential as PublicKeyCredential);
    })
  );
