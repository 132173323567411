/**
 * Fido2
 */

export enum Fido2Error {
  UNKNOWN = 0
}

export type GetNativeResponseReturnType = string | null;

export type GetNavigatorCredentialsReturnType = string | null;
